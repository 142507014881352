import {
  Alert,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function UserVerificationAlert({ isUser }) {
  const theme = useTheme();

  if (!isUser) return null;

  return (
    <Accordion
      sx={{
        mt: 2,
        "&.MuiPaper-root": {
          backgroundColor: "transparent",
          backgroundImage: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: theme.palette.warning.main }} />}
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.warning.main}`,
          "&:hover": {
            backgroundColor: theme.palette.background.paper,
          },
          "& .MuiAccordionSummary-content": {
            margin: "8px 0",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: theme.palette.text.primary,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          ⚠️ Important: Verification Steps Before Approval
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              User Verification:
            </Typography>
            <Box component="ul" sx={{ m: 0, pl: 2, mb: 2 }}>
              <li>Check user's profile thoroughly</li>
              <li>Set appropriate user level first</li>
              <li>Match collection level with user level</li>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Category Verification:
            </Typography>
            <Box component="ul" sx={{ m: 0, pl: 2, mb: 2 }}>
              <li>
                Carefully review and select the appropriate global category
              </li>
              <li>Ensure the subcategory matches the collection's content</li>
              <li>Collection can be rejected if categorized incorrectly</li>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography variant="body2" sx={{ mt: 1, fontWeight: "medium" }}>
              Rejection Criteria:
            </Typography>
            <Box component="ul" sx={{ m: 0, pl: 2 }}>
              <li>Incomplete or inaccurate profile information</li>
              <li>Content quality does not meet platform standards</li>
            </Box>

            <Typography
              variant="caption"
              color="warning.dark"
              sx={{
                display: "block",
                mt: 2,
                fontWeight: "medium",
              }}
            >
              ⚠️ These settings and verifications cannot be modified after the
              user becomes a creator
            </Typography>
          </Box>
        </Alert>
      </AccordionDetails>
    </Accordion>
  );
}
