import React, { useState } from "react";
import {
  TextField,
  Stack,
  Button,
  DialogActions,
  Box,
  FormControlLabel,
  Switch,
  Typography,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const SimpleCategoryForm = ({
  initialData,
  onSubmit,
  onCancel,
  globalCategories,
  selectedGlobalCategory,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    slug: "",
    order: 0,
    isEnabled: true,
    globalCategory: selectedGlobalCategory
      ? {
          id: selectedGlobalCategory._id,
          title: selectedGlobalCategory.title,
        }
      : {
          id: "",
          title: "",
        },
    ...initialData,
  });

  const [iconFile, setIconFile] = useState(null);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.globalCategory.id) {
      newErrors.globalCategory = "Global category is required";
    }

    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }

    if (!formData.slug.trim()) {
      newErrors.slug = "Slug is required";
    } else if (!/^[a-z0-9-]+$/.test(formData.slug)) {
      newErrors.slug =
        "Slug can only contain lowercase letters, numbers, and hyphens";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "globalCategory") {
      const selectedCategory = globalCategories.find(
        (cat) => cat._id === value
      );
      setFormData((prev) => ({
        ...prev,
        globalCategory: {
          id: selectedCategory._id,
          title: selectedCategory.title,
        },
      }));
      if (errors.globalCategory) {
        setErrors((prev) => ({ ...prev, globalCategory: undefined }));
      }
    } else {
      const newValue = type === "checkbox" ? checked : value;
      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));

      // Clear error when field is edited
      if (errors[name]) {
        setErrors((prev) => ({ ...prev, [name]: undefined }));
      }

      // Автоматически генерируем slug из title
      if (name === "title" && !formData.slug) {
        const slug = value
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^a-z0-9-]/g, "");
        setFormData((prev) => ({
          ...prev,
          slug,
        }));
      }
    }
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setIconFile(file);
        if (errors.icon) {
          setErrors((prev) => ({ ...prev, icon: undefined }));
        }
      } else {
        setErrors((prev) => ({ ...prev, icon: "Please upload an image file" }));
      }
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = new FormData();

      // Добавляем основные поля
      Object.keys(formData).forEach((key) => {
        if (key === "globalCategory") {
          data.append("globalCategory[id]", formData.globalCategory.id);
          data.append("globalCategory[title]", formData.globalCategory.title);
        } else if (key !== "icon") {
          data.append(key, formData[key]);
        }
      });

      // Добавляем icon только если он новый
      if (iconFile) {
        data.append("icon", iconFile);
      }

      onSubmit(data);
    }
  };

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <TextField
        select
        fullWidth
        label="Global Category"
        name="globalCategory"
        value={formData.globalCategory.id}
        onChange={handleChange}
        required
        disabled={!!selectedGlobalCategory}
        error={!!errors.globalCategory}
        helperText={errors.globalCategory}
      >
        {globalCategories.map((category) => (
          <MenuItem key={category._id} value={category._id}>
            {category.title}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        label="Title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        required
        error={!!errors.title}
        helperText={errors.title}
      />

      <TextField
        fullWidth
        label="Description"
        name="description"
        value={formData.description}
        multiline
        rows={3}
        onChange={handleChange}
        required
        error={!!errors.description}
        helperText={errors.description}
      />

      <TextField
        fullWidth
        label="Slug"
        name="slug"
        value={formData.slug}
        onChange={handleChange}
        required
        error={!!errors.slug}
        helperText={
          errors.slug ||
          "Technical name for the category (e.g., 'my-simple-category')"
        }
      />

      <TextField
        fullWidth
        label="Order"
        name="order"
        type="number"
        value={formData.order}
        onChange={handleChange}
        InputProps={{ inputProps: { min: 0 } }}
      />

      <Stack spacing={1}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>Icon (Optional):</Typography>
          <input type="file" accept="image/*" onChange={handleIconChange} />
        </Stack>
        {errors.icon && <FormHelperText error>{errors.icon}</FormHelperText>}
      </Stack>

      {(initialData?.icon?.previewUrl || formData.icon?.previewUrl) && (
        <Box
          component="img"
          src={initialData?.icon?.previewUrl || formData.icon?.previewUrl}
          alt="Category Icon"
          sx={{
            width: 100,
            height: 100,
            objectFit: "cover",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        />
      )}

      <FormControlLabel
        control={
          <Switch
            checked={formData.isEnabled}
            onChange={handleChange}
            name="isEnabled"
          />
        }
        label="Enabled"
      />

      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            !formData.title.trim() ||
            !formData.description.trim() ||
            !formData.slug.trim() ||
            !formData.globalCategory.id
          }
        >
          {initialData ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default SimpleCategoryForm;
