// src/pages/CreateEditGalleryPage.jsx
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Alert,
  Box,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createGallery,
  fetchGalleryById,
  updateGallery,
} from "../../Components/Galleries/api/galleries";

const initialFormState = {
  title: "",
  description: "",
  coordinates: {
    latitude: "",
    longitude: "",
  },
  isEnabled: true,
};

function CreateEditGalleryPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormState);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const isEditMode = !!id;

  useEffect(() => {
    if (isEditMode) {
      loadGallery();
    }
  }, [id]);

  const loadGallery = async () => {
    try {
      setLoading(true);
      const gallery = await fetchGalleryById(id);
      setFormData({
        title: gallery.title,
        description: gallery.description || "",
        coordinates: gallery.coordinates,
        isEnabled: gallery.isEnabled,
      });
      setPreview(gallery.image.previewUrl);
    } catch (err) {
      setError("Ошибка при загрузке галереи");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "latitude" || name === "longitude") {
      setFormData({
        ...formData,
        coordinates: {
          ...formData.coordinates,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setImage(file);
        setPreview(URL.createObjectURL(file));
        setError("");
      } else {
        setError("Пожалуйста, загрузите изображение");
      }
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    setPreview("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!isEditMode && !image) {
      setError("Пожалуйста, загрузите изображение");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title.trim());
      formDataToSend.append("description", formData.description.trim());
      formDataToSend.append(
        "coordinates",
        JSON.stringify({
          latitude: parseFloat(formData.coordinates.latitude),
          longitude: parseFloat(formData.coordinates.longitude),
        })
      );
      formDataToSend.append("isEnabled", formData.isEnabled);

      if (image) {
        formDataToSend.append("image", image);
      }

      if (isEditMode) {
        await updateGallery(id, formDataToSend);
      } else {
        await createGallery(formDataToSend);
      }

      navigate("/galleries");
    } catch (err) {
      setError(
        err.message ||
          "Ошибка при " + (isEditMode ? "обновлении" : "создании") + " галереи"
      );
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {isEditMode ? "Редактировать" : "Создать"} NFT Галерею
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            required
            label="Название"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            margin="normal"
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Описание"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            margin="normal"
          />

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="number"
                label="Широта"
                name="latitude"
                value={formData.coordinates.latitude}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="number"
                label="Долгота"
                name="longitude"
                value={formData.coordinates.longitude}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 3,
              p: 3,
              border: "2px dashed #ccc",
              borderRadius: 1,
              textAlign: "center",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="image-upload"
            />
            <label htmlFor="image-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Загрузить изображение
              </Button>
            </label>

            {preview && (
              <Box
                sx={{ mt: 2, position: "relative", display: "inline-block" }}
              >
                <img
                  src={preview}
                  alt="Preview"
                  style={{ maxHeight: 200, maxWidth: "100%" }}
                />
                <Button
                  onClick={handleImageDelete}
                  startIcon={<DeleteIcon />}
                  color="error"
                  sx={{ mt: 1 }}
                  disabled={isEditMode && !image}
                >
                  Удалить
                </Button>
              </Box>
            )}
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={formData.isEnabled}
                onChange={(e) =>
                  setFormData({ ...formData, isEnabled: e.target.checked })
                }
                name="isEnabled"
              />
            }
            label="Включить галерею"
            sx={{ mt: 2 }}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
            <Button
              type="button"
              variant="outlined"
              size="large"
              onClick={() => navigate("/galleries")}
              sx={{ flex: 1 }}
            >
              Отмена
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{ flex: 1 }}
            >
              {isEditMode ? "Сохранить" : "Создать"}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default CreateEditGalleryPage;
