import {
  Card,
  CardContent,
  Typography,
  Stack,
  Divider,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  Language,
  Email,
  Phone,
  LocationOn,
} from "@mui/icons-material";
import InfoField from "./InfoField";

// Маппинг иконок для социальных сетей
const socialIcons = {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  linkedin: LinkedIn,
  website: Language,
};

const ContactInfo = ({ profile, socialLinks }) => (
  <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Contact Information
      </Typography>
      <Stack spacing={2}>
        <InfoField
          icon={<Email fontSize="small" />}
          label="Email"
          value={profile?.email}
        />
        <InfoField
          icon={<Phone fontSize="small" />}
          label="Phone"
          value={profile?.phone}
        />
        <InfoField
          icon={<LocationOn fontSize="small" />}
          label="Country"
          value={profile?.country}
        />

        {socialLinks.length > 0 && (
          <>
            <Divider />
            <Typography variant="subtitle2">Social Links</Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {socialLinks.map((social, index) => {
                const IconComponent = socialIcons[social.icon];
                return (
                  <Tooltip key={index} title={social.label}>
                    <IconButton
                      size="small"
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconComponent />
                    </IconButton>
                  </Tooltip>
                );
              })}
            </Box>
          </>
        )}
      </Stack>
    </CardContent>
  </Card>
);

export default ContactInfo;
