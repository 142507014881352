import { Box, Typography } from "@mui/material";

const InfoField = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 0.5 }}>
    {icon && <Box sx={{ minWidth: 30, color: "text.secondary" }}>{icon}</Box>}
    <Box sx={{ flex: 1 }}>
      <Typography variant="caption" color="text.secondary" display="block">
        {label}
      </Typography>
      <Typography variant="body2">{value || "Not specified"}</Typography>
    </Box>
  </Box>
);

export default InfoField;
