import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { Favorite } from "@mui/icons-material";

const CollectionsList = ({ data }) => {
  console.log("CollectionsList received props:", { data });
  console.log(
    "CollectionsList data type:",
    Array.isArray(data) ? "array" : typeof data
  );

  // Защита от undefined
  if (!data) {
    console.log("CollectionsList: data is undefined");
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography color="text.secondary">No data available</Typography>
      </Box>
    );
  }

  // Проверка на пустой массив
  if (!Array.isArray(data) || data.length === 0) {
    console.log("CollectionsList: data is empty array or not an array");
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography color="text.secondary">No collections found</Typography>
      </Box>
    );
  }

  console.log("CollectionsList: rendering", data.length, "collections");

  return (
    <Grid container spacing={2}>
      {data.map((collection) => {
        console.log("Rendering collection:", collection._id);
        return (
          <Grid item xs={12} sm={6} key={collection._id}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      overflow: "hidden",
                      borderRadius: 1,
                      flexShrink: 0,
                    }}
                  >
                    <img
                      src={
                        collection.avatar?.previewUrl || "/default-avatar.jpg"
                      }
                      alt={collection.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onError={(e) => {
                        console.log(
                          "Image load error for collection:",
                          collection._id
                        );
                        e.target.src = "/default-avatar.jpg";
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {collection.name || "Unnamed Collection"}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 2,
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {collection.description || "No description"}
                    </Typography>
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      <Chip
                        size="small"
                        label={collection.categoryTitle}
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        size="small"
                        icon={<Favorite fontSize="small" />}
                        label={collection.likes}
                        variant="outlined"
                      />
                      <Chip
                        size="small"
                        label={`Level ${collection.level}`}
                        color="secondary"
                        variant="outlined"
                      />
                      {collection.status && (
                        <Chip
                          size="small"
                          label={collection.status}
                          color={
                            collection.status === "published"
                              ? "success"
                              : "warning"
                          }
                          variant="outlined"
                        />
                      )}
                    </Stack>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default React.memo(CollectionsList);
