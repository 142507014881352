import React from "react";
import { Box, IconButton, Typography, Tooltip, Stack } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Category as CategoryIcon,
  Dashboard as DashboardIcon,
  Add as AddIcon,
} from "@mui/icons-material";

export const GlobalCategoryTreeItem = ({
  category,
  onEdit,
  onDelete,
  onAddSimple,
  simpleCategories,
  onEditSimple,
  onDeleteSimple,
}) => {
  // Проверяем наличие категории и id
  if (!category || !category._id) {
    console.log("Invalid category:", category);
    return null;
  }

  // Получаем простые категории для текущей глобальной категории
  const simpleCategoriesForGlobal = simpleCategories[category._id] || [];

  return (
    <TreeItem
      nodeId={`global-${category._id}`}
      itemId={`global-${category._id}`} // Добавлен проп itemId
      label={
        <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
          <CategoryIcon sx={{ mr: 1 }} />
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            {category.title}
            {category.collectionsCount > 0 &&
              ` (${category.collectionsCount} collections)`}
            {category.nftsCount > 0 && ` (${category.nftsCount} NFTs)`}
            {category.categoriesCount > 0 &&
              ` (${category.categoriesCount} categories)`}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Add Simple Category">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddSimple(category);
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Global Category">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(category);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Global Category">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(category._id);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      }
    >
      {simpleCategoriesForGlobal.map((simpleCategory) => (
        <SimpleCategoryTreeItem
          key={`simple-${simpleCategory._id}`}
          category={simpleCategory}
          onEdit={() => onEditSimple(simpleCategory)}
          onDelete={() => onDeleteSimple(simpleCategory._id)}
        />
      ))}
    </TreeItem>
  );
};

export const SimpleCategoryTreeItem = ({ category, onEdit, onDelete }) => {
  // Проверяем наличие категории и id
  if (!category || !category._id) {
    console.log("Invalid simple category:", category);
    return null;
  }

  return (
    <TreeItem
      nodeId={`simple-${category._id}`}
      itemId={`simple-${category._id}`} // Добавлен проп itemId
      label={
        <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
          <DashboardIcon sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {category.title}
            {category.collectionsCount > 0 &&
              ` (${category.collectionsCount} collections)`}
            {category.nftsCount > 0 && ` (${category.nftsCount} NFTs)`}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Edit Simple Category">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Simple Category">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      }
    />
  );
};
