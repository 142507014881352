import {
  Alert,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function NFTVerificationAlert({
  isUser,
  nftType,
  globalCategory,
}) {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        mt: 2,
        "&.MuiPaper-root": {
          backgroundColor: "transparent",
          backgroundImage: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: theme.palette.warning.main }} />}
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.warning.main}`,
          "&:hover": {
            backgroundColor: theme.palette.background.paper,
          },
          "& .MuiAccordionSummary-content": {
            margin: "8px 0",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: theme.palette.text.primary,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          ⚠️ Important: NFT Verification Guidelines
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Content Type Verification:
            </Typography>
            <Box component="ul" sx={{ m: 0, pl: 2, mb: 2 }}>
              <li>
                Verify NFT type (image/audio/video) matches collection's global
                category
              </li>
              <li>Image NFTs must be in Image category collections</li>
              <li>Audio NFTs must be in Music category collections</li>
              <li>Video NFTs must be in Video category collections</li>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Category Verification:
            </Typography>
            <Box component="ul" sx={{ m: 0, pl: 2, mb: 2 }}>
              <li>Check if NFT content matches collection's category theme</li>
              <li>Ensure the content quality meets collection standards</li>
              <li>Verify artwork originality and ownership rights</li>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography variant="body2" sx={{ mt: 1, fontWeight: "medium" }}>
              Rejection Criteria:
            </Typography>
            <Box component="ul" sx={{ m: 0, pl: 2 }}>
              <li>
                NFT type doesn't match collection's global category (e.g., video
                in image collection)
              </li>
              <li>Content doesn't match collection's theme or category</li>
              <li>
                Prohibited content:
                <ul>
                  <li>Pornographic content</li>
                  <li>Violence promotion</li>
                  <li>War propaganda</li>
                  <li>Hate speech or discrimination</li>
                </ul>
              </li>
            </Box>

            <Typography
              variant="caption"
              color="warning.dark"
              sx={{
                display: "block",
                mt: 2,
                fontWeight: "medium",
              }}
            >
              ⚠️ Carefully review NFT content and type before approval.
              Incorrect approvals may affect platform quality.
            </Typography>
          </Box>
        </Alert>
      </AccordionDetails>
    </Accordion>
  );
}
