import { Box, Typography, TextField } from "@mui/material";
import { Lock } from "@mui/icons-material";

export default function LevelField({ value, onChange, isCreator }) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant="body2"
        sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}
      >
        Level:
        {isCreator && <Lock fontSize="small" color="action" />}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={value}
          onChange={onChange}
          disabled={isCreator}
          inputProps={{ min: 1, max: 12 }}
          sx={{ width: "100px" }}
        />
        {isCreator && (
          <Typography variant="caption" color="text.secondary">
            Level cannot be changed for creators
          </Typography>
        )}
      </Box>
    </Box>
  );
}
