import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Edit from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";

const UserInfo = ({ user, onLevelChange }) => (
  <Box sx={{ pl: 2, mb: 4, mt: { xs: 8, sm: 0 } }}>
    <Typography variant="h4" gutterBottom>
      {user.userName || "Anonymous"}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
      {user.walletAddress}
    </Typography>
    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      <Chip
        label={`Level ${user.level}`}
        color="primary"
        onDelete={user.type === "user" ? onLevelChange : undefined}
        deleteIcon={<Edit />}
      />
      <Chip label={`Type: ${user.type}`} variant="outlined" />
      <Chip label={`Boost: ${user.boost}x`} color="secondary" />
      {user.type === "user" && (
        <Alert severity="warning" sx={{ width: "100%", mt: 1 }}>
          You can change the user level while the status is "user"
        </Alert>
      )}
    </Stack>
  </Box>
);

export default UserInfo;
