import React, { useState } from "react";
import {
  TextField,
  Stack,
  Button,
  DialogActions,
  Box,
  FormControlLabel,
  Switch,
  Typography,
  FormHelperText,
} from "@mui/material";

const GlobalCategoryForm = ({ initialData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    slug: "",
    order: 0,
    isEnabled: true,
    ...initialData,
  });

  const [iconFile, setIconFile] = useState(null);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }

    if (!formData.slug.trim()) {
      newErrors.slug = "Slug is required";
    } else if (!/^[a-z0-9-]+$/.test(formData.slug)) {
      newErrors.slug =
        "Slug can only contain lowercase letters, numbers, and hyphens";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    // Clear error when field is edited
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }

    // Автоматически генерируем slug из title
    if (name === "title" && !formData.slug) {
      const slug = value
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "");
      setFormData((prev) => ({
        ...prev,
        slug,
      }));
    }
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setIconFile(file);
        if (errors.icon) {
          setErrors((prev) => ({ ...prev, icon: undefined }));
        }
      } else {
        setErrors((prev) => ({ ...prev, icon: "Please upload an image file" }));
      }
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = new FormData();

      // Добавляем все поля формы кроме icon
      Object.keys(formData).forEach((key) => {
        if (key !== "icon") {
          data.append(key, formData[key]);
        }
      });

      // Добавляем icon только если он новый
      if (iconFile) {
        data.append("icon", iconFile);
      }

      onSubmit(data);
    }
  };

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <TextField
        fullWidth
        label="Title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        error={!!errors.title}
        helperText={errors.title}
        required
      />

      <TextField
        fullWidth
        label="Description"
        name="description"
        value={formData.description}
        multiline
        rows={3}
        onChange={handleChange}
        error={!!errors.description}
        helperText={errors.description}
        required
      />

      <TextField
        fullWidth
        label="Slug"
        name="slug"
        value={formData.slug}
        onChange={handleChange}
        error={!!errors.slug}
        helperText={
          errors.slug || "Technical name for the category (e.g., 'my-category')"
        }
        required
      />

      <TextField
        fullWidth
        label="Order"
        name="order"
        type="number"
        value={formData.order}
        onChange={handleChange}
        InputProps={{ inputProps: { min: 0 } }}
      />

      <Stack spacing={1}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>Icon (Optional):</Typography>
          <input type="file" accept="image/*" onChange={handleIconChange} />
        </Stack>
        {errors.icon && <FormHelperText error>{errors.icon}</FormHelperText>}
      </Stack>

      {(initialData?.icon?.previewUrl || formData.icon?.previewUrl) && (
        <Box
          component="img"
          src={initialData?.icon?.previewUrl || formData.icon?.previewUrl}
          alt="Category Icon"
          sx={{
            width: 100,
            height: 100,
            objectFit: "cover",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        />
      )}

      <FormControlLabel
        control={
          <Switch
            checked={formData.isEnabled}
            onChange={handleChange}
            name="isEnabled"
          />
        }
        label="Enabled"
      />

      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            !formData.title.trim() ||
            !formData.description.trim() ||
            !formData.slug.trim()
          }
        >
          {initialData ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default GlobalCategoryForm;
