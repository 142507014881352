import { Box, Typography, Card, CardContent } from "@mui/material";

export default function CollectionImages({
  avatar,
  banner,
  placeholderAvatar,
  onImageOpen,
}) {
  return (
    <>
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Typography variant="caption">Collection Avatar</Typography>
        <Card
          sx={{ cursor: "pointer" }}
          onClick={() => onImageOpen(avatar?.fileUrl)}
        >
          <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <img
              src={avatar?.previewUrl || placeholderAvatar}
              alt="Collection Avatar"
              style={{ width: "100%", height: "120px", objectFit: "cover" }}
            />
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Typography variant="caption">Collection Banner</Typography>
        <Card
          sx={{ cursor: "pointer" }}
          onClick={() => onImageOpen(banner?.fileUrl)}
        >
          <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <img
              src={banner?.previewUrl || placeholderAvatar}
              alt="Collection Banner"
              style={{ width: "100%", height: "120px", objectFit: "cover" }}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
