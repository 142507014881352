// constants.js

// Причины отклонения коллекции
export const rejectReasons = [
  {
    id: "incomplete",
    label: "Incomplete Data",
    description: "Not all required collection information has been provided",
  },
  {
    id: "low_quality",
    label: "Low Quality Content",
    description: "Images or descriptions do not meet the platform standards",
  },
  {
    id: "rules_violation",
    label: "Rules Violation",
    description: "Collection does not comply with platform rules",
  },
  {
    id: "unoriginal",
    label: "Unoriginal Content",
    description: "Collection materials are copied from other sources",
  },
  {
    id: "technical",
    label: "Technical Issues",
    description: "Bugs or incorrect data structure detected",
  },
];

// Стили модального окна
export const modalStyle = (theme) => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "800px",
  maxHeight: "90vh",
  bgcolor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  p: 4,
  borderRadius: "8px",
  overflowY: "auto",
  color: theme.palette.text.primary,
});

// Можно добавить другие константы, например:
export const DEFAULT_LEVEL = 1;
export const MAX_LEVEL = 12;

export const SOCIAL_LINKS = [
  {
    name: "Telegram",
    icon: "telegram",
  },
  {
    name: "Facebook",
    icon: "facebook",
  },
  {
    name: "Twitter",
    icon: "twitter",
  },
  {
    name: "YouTube",
    icon: "youtube",
  },
];
