import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
  Chip,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { BASE_URL } from "../../services/baseURL";
import AddNFTModal from "../../Components/Galleries/AddNFTModal/AddNFTModal";

function GalleryDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [isAddNFTModalOpen, setIsAddNFTModalOpen] = useState(false);

  const handleAddNFT = async (selectedNFT) => {
    try {
      const response = await fetch(`${BASE_URL}/api/nft-showcases/${id}/nfts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nftId: selectedNFT.id,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add NFT");
      }

      // Перезагружаем данные галереи
      await loadGallery();
      setIsAddNFTModalOpen(false);
    } catch (err) {
      setError("Ошибка при добавлении NFT");
      console.error(err);
    }
  };

  useEffect(() => {
    loadGallery();
  }, [id]);

  const loadGallery = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/nft-showcases/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch gallery");
      }
      const data = await response.json();
      setGallery(data);
    } catch (err) {
      setError("Ошибка при загрузке галереи");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Вы уверены, что хотите удалить эту галерею?")) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/nft-showcases/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete gallery");
      }
      navigate("/galleries");
    } catch (err) {
      setError("Ошибка при удалении галереи");
    }
  };

  const handleDeleteNft = async (nftId) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/nft-showcases/${id}/nfts/${nftId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete NFT");
      }
      loadGallery(); // Перезагружаем галерею после удаления
    } catch (err) {
      setError("Ошибка при удалении NFT");
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!gallery) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="info">Галерея не найдена</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ mb: 4, overflow: "hidden" }}>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            height="300"
            image={gallery.image?.fileUrl}
            alt={gallery.title}
            sx={{ objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              p: 2,
            }}
          >
            <Typography variant="h4" component="h1">
              {gallery.title}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Box>
              <Chip
                label={gallery.isEnabled ? "Активна" : "Неактивна"}
                color={gallery.isEnabled ? "success" : "default"}
                sx={{ mr: 1 }}
              />
              <Box sx={{ display: "inline-flex", alignItems: "center", ml: 2 }}>
                <LocationOnIcon color="action" />
                <Typography variant="body2" color="text.secondary">
                  {`${gallery.coordinates?.latitude}, ${gallery.coordinates?.longitude}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton onClick={() => navigate(`/galleries/edit/${id}`)}>
                <EditIcon />
              </IconButton>
              <IconButton color="error" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>

          {gallery.description && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              {gallery.description}
            </Typography>
          )}

          <Divider sx={{ my: 2 }} />

          {/* NFTs Section */}
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">NFTs в галерее</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setIsAddNFTModalOpen(true)}
              >
                Добавить NFT
              </Button>
            </Box>

            <Grid container spacing={3}>
              {gallery.nfts &&
                gallery.nfts.map((nft) => (
                  <Grid item xs={12} sm={6} md={4} key={nft._id}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="200"
                        image={nft.image?.previewUrl}
                        alt={nft.name}
                        sx={{ objectFit: "cover" }}
                      />
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {nft.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {nft.description}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          {nft.category && (
                            <Chip
                              label={nft.category.title}
                              size="small"
                              sx={{ mr: 1 }}
                            />
                          )}
                          {nft.globalCategory && (
                            <Chip
                              label={nft.globalCategory.title}
                              size="small"
                            />
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Уровень: {nft.level}
                          </Typography>
                          <Button
                            size="small"
                            color="error"
                            onClick={() => handleDeleteNft(nft._id)}
                          >
                            Удалить
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>

            {(!gallery.nfts || gallery.nfts.length === 0) && (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <Typography color="text.secondary">
                  В этой галерее пока нет NFT
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
      <AddNFTModal
        open={isAddNFTModalOpen}
        onClose={() => setIsAddNFTModalOpen(false)}
        onSelect={handleAddNFT}
      />
    </Container>
  );
}

export default GalleryDetailsPage;
