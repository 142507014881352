import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Typography,
} from "@mui/material";

import { rejectReasons, modalStyle } from "./constants";
import { BASE_URL } from "../../services/baseURL";
import UserVerificationAlert from "./Moderation/UserVerificationAlert";
import UserHeader from "./Moderation/UserHeader";
import LevelField from "./Moderation/LevelField";
import CollectionImages from "./Moderation/CollectionImages";

export default function ModerationCollectionsModal({
  open,
  onClose,
  selectedRow,
  placeholderAvatar,
  handleImageOpen,
  onUpdate,
}) {
  const navigate = useNavigate();
  const [level, setLevel] = useState("");
  const [globalCategories, setGlobalCategories] = useState([]);
  const [simpleCategories, setSimpleCategories] = useState([]);
  const [selectedGlobalCategory, setSelectedGlobalCategory] = useState(null);
  const [selectedSimpleCategory, setSelectedSimpleCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRejectReason, setSelectedRejectReason] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    } else if (selectedRow) {
      initializeForm();
    }
  }, [open, selectedRow]);

  const resetForm = () => {
    setGlobalCategories([]);
    setSimpleCategories([]);
    setSelectedGlobalCategory(null);
    setSelectedSimpleCategory(null);
    setLevel("");
    setSelectedRejectReason("");
  };

  const initializeForm = () => {
    setLevel(selectedRow.level || "");
    fetchGlobalCategories();

    if (selectedRow.globalCategory) {
      setSelectedGlobalCategory({
        id: selectedRow.globalCategory.id,
        title: selectedRow.globalCategory.title,
      });
      fetchSimpleCategories(selectedRow.globalCategory.id);
    }
    if (selectedRow.category) {
      setSelectedSimpleCategory({
        id: selectedRow.category.id,
        title: selectedRow.category.title,
      });
    }
  };

  const fetchGlobalCategories = async () => {
    try {
      const response = await fetch(`${BASE_URL}/global-categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      setGlobalCategories(data.data || []);
    } catch (error) {
      console.error("Error fetching global categories:", error);
      setGlobalCategories([]);
      setSnackbar({
        open: true,
        message: "Failed to load categories",
        severity: "error",
      });
    }
  };

  const fetchSimpleCategories = async (globalCategoryId) => {
    if (!globalCategoryId) return;
    try {
      const response = await fetch(
        `${BASE_URL}/api/simple-categories/by-global-category/${globalCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setSimpleCategories(data.data || []);
    } catch (error) {
      console.error("Error fetching simple categories:", error);
      setSimpleCategories([]);
      setSnackbar({
        open: true,
        message: "Failed to load subcategories",
        severity: "error",
      });
    }
  };

  const handleGlobalCategoryChange = async (event) => {
    const selected = globalCategories.find(
      (cat) => cat._id === event.target.value
    );
    if (selected) {
      setSelectedGlobalCategory({
        id: selected._id,
        title: selected.title,
      });
      setSelectedSimpleCategory(null);
      await fetchSimpleCategories(selected._id);
    } else {
      setSelectedGlobalCategory(null);
      setSimpleCategories([]);
    }
  };

  const handleSimpleCategoryChange = (event) => {
    const selected = simpleCategories.find(
      (cat) => cat._id === event.target.value
    );
    setSelectedSimpleCategory(
      selected
        ? {
            id: selected._id,
            title: selected.title,
          }
        : null
    );
  };

  const handleUserClick = () => {
    if (selectedRow?.user?._id) {
      navigate(`/users/${selectedRow.user._id}`);
      onClose();
    }
  };

  const handleCheckProfile = () => {
    if (selectedRow?.user?._id) {
      navigate(`/users/${selectedRow.user._id}`);
    }
  };

  const handleApproveClick = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BASE_URL}/api/collection/${selectedRow._id}/approve`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            level: parseInt(level),
            globalCategory: selectedGlobalCategory,
            category: selectedSimpleCategory,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to approve collection");
      }

      setSnackbar({
        open: true,
        message: "Collection approved successfully",
        severity: "success",
      });
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error approving collection:", error);
      setSnackbar({
        open: true,
        message: "Failed to approve collection",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRejectClick = async () => {
    try {
      setLoading(true);
      const selectedReason = rejectReasons.find(
        (r) => r.id === selectedRejectReason
      );
      const rejectMessage = `${selectedReason.label}: ${selectedReason.description}`;

      const response = await fetch(
        `${BASE_URL}/api/collection/${selectedRow._id}/reject`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ message: rejectMessage }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reject collection");
      }

      setSnackbar({
        open: true,
        message: "Collection rejected successfully",
        severity: "success",
      });
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error rejecting collection:", error);
      setSnackbar({
        open: true,
        message: "Failed to reject collection",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!open || !selectedRow) return null;

  const isCreator = selectedRow.user?.type === "creator";

  return (
    <Box sx={modalStyle}>
      <UserHeader
        user={selectedRow.user}
        avatar={placeholderAvatar}
        onClick={handleUserClick}
        onCheck={handleCheckProfile}
      />

      <UserVerificationAlert isUser={!isCreator} />

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Title:</strong> {selectedRow.name || "No title"}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Description:</strong>{" "}
            {selectedRow.description || "No description"}
          </Typography>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Global Category</InputLabel>
            <Select
              value={selectedGlobalCategory?.id || ""}
              onChange={handleGlobalCategoryChange}
              label="Global Category"
            >
              {globalCategories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            sx={{ mb: 2 }}
            disabled={!selectedGlobalCategory}
          >
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedSimpleCategory?.id || ""}
              onChange={handleSimpleCategoryChange}
              label="Category"
            >
              {simpleCategories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LevelField
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            isCreator={isCreator}
          />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Rejection Reason</InputLabel>
            <Select
              value={selectedRejectReason}
              onChange={(e) => setSelectedRejectReason(e.target.value)}
              label="Rejection Reason"
            >
              {rejectReasons.map((reason) => (
                <MenuItem key={reason.id} value={reason.id}>
                  {reason.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedRejectReason && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {
                rejectReasons.find((r) => r.id === selectedRejectReason)
                  ?.description
              }
            </Alert>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <CollectionImages
            avatar={selectedRow.avatar}
            banner={selectedRow.banner}
            placeholderAvatar={placeholderAvatar}
            onImageOpen={handleImageOpen}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApproveClick}
          disabled={
            loading ||
            !selectedGlobalCategory ||
            !selectedSimpleCategory ||
            !level
          }
        >
          {loading ? <CircularProgress size={24} /> : "Approve"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleRejectClick}
          disabled={loading || !selectedRejectReason}
        >
          {loading ? <CircularProgress size={24} /> : "Reject"}
        </Button>
      </Box>
    </Box>
  );
}
