import { Box, Button, Card, Avatar, Skeleton, useTheme } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AccountCircle from "@mui/icons-material/AccountCircle";

const ProfileHeader = ({ user, onBackClick }) => {
  const theme = useTheme();
  console.log(user);
  return (
    <Box>
      <Box sx={{ mb: 2, mt: 2 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={onBackClick}
          variant="outlined"
        >
          Back
        </Button>
      </Box>

      <Box sx={{ mb: 8, position: "relative" }}>
        {" "}
        {/* Заменил Card на Box */}
        <Card sx={{ mb: 4 }}>
          <Box sx={{ height: 200, overflow: "hidden" }}>
            {user.profile?.banner?.fileUrl ? (
              <img
                src={user.profile.banner.fileUrl}
                alt="Profile Banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  bgcolor:
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "grey.700" : "grey.200",
                  }}
                />
              </Box>
            )}
          </Box>
        </Card>
        {user.profile?.photo?.fileUrl ? (
          <Avatar
            src={user.profile.photo.fileUrl}
            sx={{
              width: 120,
              height: 120,
              position: "absolute",
              bottom: 0,
              left: 32,
              border: `4px solid ${theme.palette.background.paper}`,
              boxShadow: 2,
              zIndex: 1,
            }}
          />
        ) : (
          <Avatar
            sx={{
              width: 120,
              height: 120,
              position: "absolute",
              bottom: 0,
              left: 32,
              border: `4px solid ${theme.palette.background.paper}`,
              boxShadow: 2,
              bgcolor: theme.palette.mode === "dark" ? "grey.800" : "grey.300",
              zIndex: 1,
            }}
          >
            <AccountCircle
              sx={{
                width: 80,
                height: 80,
                color: theme.palette.mode === "dark" ? "grey.600" : "grey.400",
              }}
            />
          </Avatar>
        )}
      </Box>
    </Box>
  );
};

export default ProfileHeader;
