import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const LevelDialog = ({
  open,
  onClose,
  value,
  onChange,
  onUpdate,
  updating,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Change User Level</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="New Level"
        type="number"
        fullWidth
        value={value}
        onChange={onChange}
        InputProps={{ inputProps: { min: 1 } }}
        helperText="Enter a value greater than 0"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="inherit">
        Cancel
      </Button>
      <Button
        onClick={onUpdate}
        disabled={updating || !value || isNaN(value) || value < 1}
        variant="contained"
        color="primary"
      >
        {updating ? "Updating..." : "Update"}
      </Button>
    </DialogActions>
  </Dialog>
);

export default LevelDialog;
