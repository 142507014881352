// src/components/Galleries/components/AddNFTModal.jsx
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  CircularProgress,
  Slider,
  IconButton,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "../../../services/baseURL";

const PriceRangeFilter = ({ value, onChange, min, max, label }) => (
  <Box sx={{ width: "100%", px: 2 }}>
    <Typography gutterBottom>{label}</Typography>
    <Slider
      value={value}
      onChange={onChange}
      valueLabelDisplay="auto"
      min={min}
      max={max}
      step={(max - min) / 100}
    />
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="caption">{min}</Typography>
      <Typography variant="caption">{max}</Typography>
    </Box>
  </Box>
);

function AddNFTModal({ open, onClose, onSelect }) {
  const [filters, setFilters] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSimpleCategory, setSelectedSimpleCategory] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [tonPriceRange, setTonPriceRange] = useState([0, 0]);
  const [tribePriceRange, setTribePriceRange] = useState([0, 0]);

  // Загрузка фильтров
  useEffect(() => {
    fetchFilters();
  }, []);

  // Загрузка NFT при изменении фильтров
  useEffect(() => {
    fetchNFTs();
  }, [
    page,
    selectedCategory,
    selectedSimpleCategory,
    selectedCollection,
    searchTerm,
  ]);

  const fetchFilters = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/marketplace/filters`);
      const data = await response.json();
      if (data.success) {
        setFilters(data.data);
        // Установка начальных значений для слайдеров цен
        setTonPriceRange([
          data.data.priceRange.ton.minPrice,
          data.data.priceRange.ton.maxPrice,
        ]);
        setTribePriceRange([
          data.data.priceRange.tribe.minPrice,
          data.data.priceRange.tribe.maxPrice,
        ]);
      }
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  const fetchNFTs = async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams();

      // Базовые параметры
      searchParams.append("page", page);
      searchParams.append("limit", 12);

      if (searchTerm) {
        searchParams.append("searchTerm", searchTerm);
      }

      // Добавляем категории как массивы
      if (selectedCategory) {
        searchParams.append("globalCategoryIds[]", selectedCategory);
      }

      if (selectedSimpleCategory) {
        searchParams.append("simpleCategoryIds[]", selectedSimpleCategory);
      }

      if (selectedCollection) {
        searchParams.append("collectionIds[]", selectedCollection);
      }

      // Добавляем диапазоны цен только если они изменились
      if (
        tonPriceRange[0] !== filters?.priceRange.ton.minPrice ||
        tonPriceRange[1] !== filters?.priceRange.ton.maxPrice
      ) {
        searchParams.append("tonPrice[min]", tonPriceRange[0]);
        searchParams.append("tonPrice[max]", tonPriceRange[1]);
      }

      if (
        tribePriceRange[0] !== filters?.priceRange.tribe.minPrice ||
        tribePriceRange[1] !== filters?.priceRange.tribe.maxPrice
      ) {
        searchParams.append("tribePrice[min]", tribePriceRange[0]);
        searchParams.append("tribePrice[max]", tribePriceRange[1]);
      }

      const response = await fetch(
        `${BASE_URL}/api/marketplace/search?${searchParams}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch NFTs");
      }

      const data = await response.json();
      if (data.success) {
        setNfts(data.items);
        setTotalPages(data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching NFTs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    fetchNFTs();
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSimpleCategory("");
    setPage(1);
  };

  const handleSimpleCategoryChange = (event) => {
    setSelectedSimpleCategory(event.target.value);
    setPage(1);
  };

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
    setPage(1);
  };

  const handleTonPriceChange = (event, newValue) => {
    setTonPriceRange(newValue);
  };

  const handleTribePriceChange = (event, newValue) => {
    setTribePriceRange(newValue);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("");
    setSelectedSimpleCategory("");
    setSelectedCollection("");
    setTonPriceRange([
      filters?.priceRange.ton.minPrice || 0,
      filters?.priceRange.ton.maxPrice || 0,
    ]);
    setTribePriceRange([
      filters?.priceRange.tribe.minPrice || 0,
      filters?.priceRange.tribe.maxPrice || 0,
    ]);
    setPage(1);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Выбрать NFT
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 3 }}>
          {/* Поиск */}
          <form onSubmit={handleSearch}>
            <TextField
              fullWidth
              placeholder="Поиск NFT..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </form>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            {/* Фильтры */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Глобальная категория</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Глобальная категория"
                >
                  <MenuItem value="">Все</MenuItem>
                  {filters?.globalCategories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.title} ({cat.nftsCount})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Категория</InputLabel>
                <Select
                  value={selectedSimpleCategory}
                  onChange={handleSimpleCategoryChange}
                  label="Категория"
                  disabled={!selectedCategory}
                >
                  <MenuItem value="">Все</MenuItem>
                  {filters?.simpleCategories
                    .filter(
                      (cat) =>
                        !selectedCategory ||
                        cat.globalCategoryId === selectedCategory
                    )
                    .map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.title} ({cat.nftsCount})
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Коллекция</InputLabel>
                <Select
                  value={selectedCollection}
                  onChange={handleCollectionChange}
                  label="Коллекция"
                >
                  <MenuItem value="">Все</MenuItem>
                  {filters?.collections
                    .filter(
                      (col) =>
                        (!selectedCategory ||
                          col.globalCategoryId === selectedCategory) &&
                        (!selectedSimpleCategory ||
                          col.simpleCategoryId === selectedSimpleCategory)
                    )
                    .map((col) => (
                      <MenuItem key={col.id} value={col.id}>
                        {col.title} ({col.nftsCount})
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <PriceRangeFilter
                value={tonPriceRange}
                onChange={handleTonPriceChange}
                min={filters?.priceRange.ton.minPrice || 0}
                max={filters?.priceRange.ton.maxPrice || 0}
                label="Цена TON"
              />

              <PriceRangeFilter
                value={tribePriceRange}
                onChange={handleTribePriceChange}
                min={filters?.priceRange.tribe.minPrice || 0}
                max={filters?.priceRange.tribe.maxPrice || 0}
                label="Цена TRIBE"
              />

              <Button
                fullWidth
                variant="outlined"
                onClick={handleReset}
                sx={{ mt: 2 }}
              >
                Сбросить фильтры
              </Button>
            </Grid>

            {/* Список NFT */}
            <Grid item xs={12} md={9}>
              {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Grid container spacing={2}>
                    {nfts.map((nft) => (
                      <Grid item xs={12} sm={6} md={4} key={nft.id}>
                        <Card
                          sx={{
                            cursor: "pointer",
                            "&:hover": { boxShadow: 6 },
                          }}
                          onClick={() => onSelect(nft)}
                        >
                          <CardMedia
                            component="img"
                            height="200"
                            image={nft.image.previewUrl}
                            alt={nft.title}
                            sx={{ objectFit: "cover" }}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h6" noWrap>
                              {nft.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {nft.description}
                            </Typography>
                            <Box sx={{ mt: 1 }}>
                              <Chip
                                label={`${nft.tonPrice} TON`}
                                size="small"
                                sx={{ mr: 1 }}
                              />
                              <Chip
                                label={`${nft.tribePrice} TRIBE`}
                                size="small"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  {nfts.length === 0 && (
                    <Box textAlign="center" py={4}>
                      <Typography color="text.secondary">
                        NFT не найдены
                      </Typography>
                    </Box>
                  )}
                  {totalPages > 1 && (
                    <Box display="flex" justifyContent="center" mt={3}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                      />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AddNFTModal;
