import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  Divider,
  Switch,
  FormControlLabel,
  Grid,
  useTheme,
  IconButton,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { BASE_URL } from "../../services/baseURL";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImagePreview = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 200,
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  backgroundColor: theme.palette.background.default,
}));

function AddRewardModal({ open, onClose, onSave }) {
  const theme = useTheme();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [newReward, setNewReward] = useState({
    name: "",
    description: "",
    acceptNftLevel: 0,
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    isActive: false,
    coefficients: {
      nftLevel: 0,
      nftLikesCount: 0,
      userBoostsCount: 0,
    },
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [dayCount, setDayCount] = useState(0);

  useEffect(() => {
    if (newReward.startDate && newReward.endDate) {
      const start = new Date(newReward.startDate);
      const end = new Date(newReward.endDate);
      const diffInMs = end - start;
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      setDayCount(Math.max(0, days));
    } else {
      setDayCount(0);
    }
  }, [newReward.startDate, newReward.endDate]);

  const resetForm = () => {
    setNewReward({
      name: "",
      description: "",
      acceptNftLevel: 0,
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      isActive: false,
      coefficients: {
        nftLevel: 0,
        nftLikesCount: 0,
        userBoostsCount: 0,
      },
    });
    setSelectedImage(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setPreviewUrl(null);
    setError("");
    setDayCount(0);
  };

  const handleFieldChange = (field, value) => {
    setError("");
    setNewReward((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedFieldChange = (field, nestedField, value) => {
    setError("");
    setNewReward((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [nestedField]: value,
      },
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError("Image size should not exceed 5MB");
        return;
      }

      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError("Please upload an image file");
        return;
      }

      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setError("");
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setPreviewUrl(null);
  };

  const validateForm = () => {
    if (!newReward.name.trim()) {
      setError("Name is required");
      return false;
    }
    if (!newReward.description.trim()) {
      setError("Description is required");
      return false;
    }
    if (!selectedImage) {
      setError("Image is required");
      return false;
    }
    if (dayCount < 0) {
      setError("End date must be after start date");
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const formData = new FormData();

      // Append image file
      formData.append("image", selectedImage);

      // Prepare data for submission
      const formattedReward = {
        name: newReward.name,
        description: newReward.description,
        acceptNftLevel: Number(newReward.acceptNftLevel),
        startDate: new Date(newReward.startDate).toISOString(),
        endDate: new Date(newReward.endDate).toISOString(),
        isActive: newReward.isActive,
        daysCount: dayCount,
      };

      // Append basic fields
      Object.keys(formattedReward).forEach((key) => {
        formData.append(key, formattedReward[key]);
      });

      // Append coefficients as nested fields
      Object.entries(newReward.coefficients).forEach(([key, value]) => {
        formData.append(`coefficients[${key}]`, Number(value));
      });

      const response = await fetch(`${BASE_URL}/rewards_season`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to create reward season");
      }

      onSave(data);
      resetForm();
      onClose();
    } catch (error) {
      setError(error.message || "Failed to add new reward season");
      console.error("Error adding new reward:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CircularProgress color="primary" />
          <Typography>Saving reward season...</Typography>
        </Box>
      </Backdrop>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{
          ".MuiPaper-root": {
            borderRadius: "8px",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2),
          },
        }}
      >
        <DialogTitle
          sx={{ borderBottom: `1px solid ${theme.palette.divider}`, mb: 1 }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Add new Reward season
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mt: 2 }}>
            {error && (
              <Alert severity="error" onClose={() => setError("")}>
                {error}
              </Alert>
            )}

            {/* Image Upload Section */}
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Season Image
              </Typography>
              <ImagePreview>
                {previewUrl ? (
                  <>
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </>
                ) : (
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </Button>
                )}
              </ImagePreview>
            </Box>

            <Divider />

            {/* Basic Information */}
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Basic Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    value={newReward.name}
                    onChange={(e) => handleFieldChange("name", e.target.value)}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Description"
                    value={newReward.description}
                    onChange={(e) =>
                      handleFieldChange("description", e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Accept NFT Level"
                    type="number"
                    value={newReward.acceptNftLevel}
                    onChange={(e) =>
                      handleFieldChange(
                        "acceptNftLevel",
                        Number(e.target.value)
                      )
                    }
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            {/* Time Frame */}
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Time Frame
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={newReward.startDate}
                    onChange={(e) =>
                      handleFieldChange("startDate", e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={newReward.endDate}
                    onChange={(e) =>
                      handleFieldChange("endDate", e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="body2"
                sx={{ mt: 2, color: theme.palette.text.secondary }}
              >
                Duration: {dayCount} {dayCount === 1 ? "day" : "days"}
              </Typography>
            </Box>

            <Divider />

            {/* Coefficients */}
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Coefficients
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="NFT Level"
                    type="number"
                    value={newReward.coefficients.nftLevel}
                    onChange={(e) =>
                      handleNestedFieldChange(
                        "coefficients",
                        "nftLevel",
                        Number(e.target.value)
                      )
                    }
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="NFT Likes Count"
                    type="number"
                    value={newReward.coefficients.nftLikesCount}
                    onChange={(e) =>
                      handleNestedFieldChange(
                        "coefficients",
                        "nftLikesCount",
                        Number(e.target.value)
                      )
                    }
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="User Boosts Count"
                    type="number"
                    value={newReward.coefficients.userBoostsCount}
                    onChange={(e) =>
                      handleNestedFieldChange(
                        "coefficients",
                        "userBoostsCount",
                        Number(e.target.value)
                      )
                    }
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            {/* Status */}
            <FormControlLabel
              control={
                <Switch
                  checked={newReward.isActive}
                  onChange={(e) =>
                    handleFieldChange("isActive", e.target.checked)
                  }
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: newReward.isActive
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    fontWeight: "bold",
                  }}
                >
                  {newReward.isActive ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "flex-end",
            borderTop: `1px solid ${theme.palette.divider}`,
            mt: 2,
            pt: 2,
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: theme.palette.text.primary,
              borderColor: theme.palette.divider,
              marginRight: theme.spacing(1),
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={loading || !selectedImage}
            sx={{
              backgroundColor: theme.palette.success.main,
              "&:hover": {
                backgroundColor: theme.palette.success.dark,
              },
              "&.Mui-disabled": {
                backgroundColor: theme.palette.action.disabledBackground,
                color: theme.palette.action.disabled,
              },
            }}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddRewardModal;
