import { Box, Typography, Button } from "@mui/material";
import { Person } from "@mui/icons-material";

export default function UserHeader({ user, avatar, onClick, onCheck }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={user?.profile?.photo?.previewUrl || avatar}
          alt="User Avatar"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "12px",
            cursor: "pointer",
          }}
          onClick={onClick}
        />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {user?.userName || "No Name"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            User Type: {user?.type || "N/A"}
          </Typography>
        </Box>
      </Box>
      {user?.type === "user" && (
        <Button
          startIcon={<Person />}
          variant="contained"
          color="info"
          onClick={onCheck}
        >
          Check Profile
        </Button>
      )}
    </Box>
  );
}
