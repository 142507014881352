import React from "react";
import GalleriesPage from "../../Components/Galleries/GalleriesPage";
const Galleries = () => {
  return (
    <div>
      <GalleriesPage />
    </div>
  );
};

export default Galleries;
