// CollectionModal.jsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Switch,
  FormControlLabel,
  Button,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../services/baseURL";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  overflowY: "auto",
};

const imageModalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  bgcolor: "rgba(0, 0, 0, 0.6)", // Мягкое затемнение фона
  zIndex: 1300,
};

const CollectionModal = ({
  open,
  onClose,
  selectedRow,
  handleToggleChange,
  refreshData,
}) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  // Состояния для категорий
  const [globalCategories, setGlobalCategories] = useState([]);
  const [simpleCategories, setSimpleCategories] = useState([]);
  const [selectedGlobalCategory, setSelectedGlobalCategory] = useState("");
  const [selectedSimpleCategory, setSelectedSimpleCategory] = useState("");
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [loadingSimple, setLoadingSimple] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  // Состояния для уведомлений
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const placeholderAvatar = "https://via.placeholder.com/150?text=No+Avatar";

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setCurrentImage(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Загрузка глобальных категорий при открытии модального окна
  useEffect(() => {
    if (open) {
      fetchGlobalCategories();
      // Установка текущих категорий, если они уже присвоены
      if (selectedRow.globalCategoryId) {
        setSelectedGlobalCategory(selectedRow.globalCategoryId);
      } else {
        setSelectedGlobalCategory("");
      }
      if (selectedRow.simpleCategoryId) {
        setSelectedSimpleCategory(selectedRow.simpleCategoryId);
      } else {
        setSelectedSimpleCategory("");
      }
    }
  }, [open, selectedRow]);

  // Загрузка простых категорий при выборе глобальной категории
  useEffect(() => {
    if (selectedGlobalCategory) {
      fetchSimpleCategories(selectedGlobalCategory);
    } else {
      setSimpleCategories([]);
      setSelectedSimpleCategory("");
    }
  }, [selectedGlobalCategory]);

  // Функция для получения глобальных категорий
  const fetchGlobalCategories = async () => {
    setLoadingGlobal(true);
    try {
      const response = await axios.get(`${BASE_URL}/global-categories`);
      if (response.data.success) {
        setGlobalCategories(response.data.data);
      }
    } catch (err) {
      console.error("Ошибка при получении глобальных категорий:", err);
      setSnackbarMessage("Ошибка при загрузке глобальных категорий.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingGlobal(false);
    }
  };

  // Функция для получения простых категорий по выбранной глобальной категории
  const fetchSimpleCategories = async (globalCategoryId) => {
    setLoadingSimple(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/simple-categories/by-global-category/${globalCategoryId}`
      );
      if (response.data.success) {
        setSimpleCategories(response.data.data);
      }
    } catch (err) {
      console.error("Ошибка при получении простых категорий:", err);
      setSnackbarMessage("Ошибка при загрузке простых категорий.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingSimple(false);
    }
  };

  // Обработчик отправки выбранных категорий
  const handleSubmitCategories = async () => {
    if (!selectedGlobalCategory || !selectedSimpleCategory) {
      setError("Пожалуйста, выберите обе категории.");
      return;
    }

    setSubmitting(true);
    setError("");

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BASE_URL}/api/collection/${selectedRow.id}/categories`,
        {
          globalCategoryId: selectedGlobalCategory,
          simpleCategoryId: selectedSimpleCategory,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Уведомление об успешном обновлении
      setSnackbarMessage("Категории успешно обновлены.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      // Обновление данных в родительском компоненте
      if (refreshData) {
        refreshData();
      }
      onClose();
    } catch (err) {
      console.error("Ошибка при обновлении категорий:", err);
      setError("Не удалось обновить категории. Попробуйте еще раз.");
      // Уведомление об ошибке
      setSnackbarMessage("Ошибка при обновлении категорий.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            {selectedRow && (
              <>
                {/* Header Section */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      selectedRow.user?.profile?.photo?.previewUrl ||
                      placeholderAvatar
                    }
                    alt="User Avatar"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginRight: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleImageClick(
                        selectedRow.user?.profile?.photo?.previewUrl ||
                          placeholderAvatar
                      )
                    }
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold" }}
                      color="text.primary"
                    >
                      {selectedRow.name || "No Name"}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      User Type:{" "}
                      <strong>{selectedRow.user?.type || "N/A"}</strong>
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 3 }} />

                {/* Content Section */}
                <Grid container spacing={2}>
                  {/* Left Section: Images */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                      <Typography variant="caption" color="text.primary">
                        Collection Avatar
                      </Typography>
                      <img
                        src={
                          selectedRow.avatar?.previewUrl ||
                          "https://via.placeholder.com/150"
                        }
                        alt="Collection Avatar"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          marginBottom: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImageClick(
                            selectedRow.avatar?.previewUrl ||
                              "https://via.placeholder.com/150"
                          )
                        }
                      />
                    </Box>
                  </Grid>

                  {/* Right Section: Information */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                        color="text.primary"
                      >
                        {selectedRow.name || "No Name"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Category:</strong>{" "}
                      {selectedRow.categoryTitle || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Global Category:</strong>{" "}
                      {selectedRow?.globalCategory?.title || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Simple Category:</strong>{" "}
                      {selectedRow?.category?.title || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Status:</strong> {selectedRow.status || "Unknown"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Level:</strong> {selectedRow.level || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      color="text.primary"
                    >
                      <strong>Likes:</strong> {selectedRow.likes || "N/A"}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selectedRow.isEnabled}
                          onChange={handleToggleChange}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          color={
                            selectedRow.isEnabled
                              ? "success.main"
                              : "error.main"
                          }
                        >
                          {selectedRow.isEnabled ? "Enabled" : "Disabled"}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="caption" color="text.primary">
                    Collection Banner
                  </Typography>
                  <img
                    src={
                      selectedRow.banner?.previewUrl ||
                      "https://via.placeholder.com/300x100"
                    }
                    alt="Collection Banner"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleImageClick(
                        selectedRow.banner?.previewUrl ||
                          "https://via.placeholder.com/300x100"
                      )
                    }
                  />
                </Box>
                <Divider sx={{ my: 3 }} />

                {/* Footer Section */}
                <Typography variant="body2" sx={{ mb: 2 }} color="text.primary">
                  <strong>Description:</strong>{" "}
                  {selectedRow.description || "No description available."}
                </Typography>

                {/* Секция присваивания категорий */}
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Присвоить категории
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="global-category-label">
                        Глобальная категория
                      </InputLabel>
                      <Select
                        labelId="global-category-label"
                        value={selectedGlobalCategory}
                        label="Глобальная категория"
                        onChange={(e) =>
                          setSelectedGlobalCategory(e.target.value)
                        }
                      >
                        {loadingGlobal ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          globalCategories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                              {category.title}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth disabled={!selectedGlobalCategory}>
                      <InputLabel id="simple-category-label">
                        Простая категория
                      </InputLabel>
                      <Select
                        labelId="simple-category-label"
                        value={selectedSimpleCategory}
                        label="Простая категория"
                        onChange={(e) =>
                          setSelectedSimpleCategory(e.target.value)
                        }
                      >
                        {loadingSimple ? (
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : simpleCategories.length > 0 ? (
                          simpleCategories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                              {category.title}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            Нет доступных простых категорий
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {error && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    {error}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={handleSubmitCategories}
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Сохранить категории"
                  )}
                </Button>

                {/* Кнопка закрытия */}
                <Button
                  variant="contained"
                  fullWidth
                  onClick={onClose}
                  sx={{
                    mt: 3,
                    bgcolor: "primary.main",
                    ":hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                >
                  Закрыть
                </Button>
              </>
            )}
          </Box>
        </Fade>
      </Modal>

      {/* Модальное окно для отображения изображений */}
      <Modal open={imageModalOpen} onClose={handleImageModalClose}>
        <Box sx={imageModalStyle} onClick={handleImageModalClose}>
          <img
            src={currentImage}
            alt="Enlarged View"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>

      {/* Уведомление */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CollectionModal;
