import { Paper, Typography } from "@mui/material";

const StatCard = ({ title, value, color = "primary.main" }) => (
  <Paper
    elevation={0}
    sx={{
      p: 1.5,
      textAlign: "center",
      border: 1,
      borderColor: "divider",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <Typography variant="h6" color={color} sx={{ fontWeight: "bold" }}>
      {value}
    </Typography>
    <Typography variant="caption" color="text.secondary">
      {title}
    </Typography>
  </Paper>
);

export default StatCard;
