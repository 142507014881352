import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  CircularProgress,
  Typography,
  Avatar,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

import { BASE_URL } from "../../services/baseURL";
import UserDetailsModal from "./UserModal";
import ExportModal from "./ExportModal";

const statusColors = {
  creator: "#4CAF50", // Green
  user: "#FFC107", // Yellow
};

export default function UsersTable() {
  // State management
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Handlers
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpen = (row) => {
    setSelectedUser(row.id);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  // Data fetching
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/users`);
        const processedData = response.data.data.map((item) => ({
          ...item,
          id: item._id,
        }));
        setData(processedData);
        setFilteredData(processedData);
      } catch (error) {
        console.error("Error fetching users:", error);
        setSnackbar({
          open: true,
          message: "Ошибка при загрузке данных пользователей.",
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter handling
  const handleFilter = (type) => {
    setActiveFilter(type);
    const filtered =
      type === "all" ? data : data.filter((item) => item.type === type);

    const searchedData = filtered.filter(
      (item) =>
        item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.walletAddress?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(searchedData);
  };

  // Search handling
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered =
      activeFilter === "all"
        ? data
        : data.filter((item) => item.type === activeFilter);

    const searchedData = filtered.filter(
      (item) =>
        item._id.toLowerCase().includes(query.toLowerCase()) ||
        item.walletAddress?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(searchedData);
  };

  // Column definitions
  const columns = [
    {
      field: "avatar",
      headerName: "",
      width: 80,
      sortable: false,
      renderCell: (params) =>
        params.row.profile?.photo?.previewUrl ? (
          <Avatar
            src={params.row.profile.photo.previewUrl}
            alt={params.row.profile?.telegram || "No Name"}
            sx={{ width: 40, height: 40 }}
          />
        ) : (
          <Avatar sx={{ width: 40, height: 40, bgcolor: "grey.300" }}>
            <Avatar
              src={params.row.avatar}
              alt={params.row.userName || "No Name"}
              sx={{ width: 40, height: 40 }}
            />
          </Avatar>
        ),
    },
    { field: "_id", headerName: "ID", width: 100 },
    { field: "userName", headerName: "Name", width: 100 },
    { field: "walletAddress", headerName: "Wallet Address", width: 200 },
    { field: "level", headerName: "Level", type: "number", width: 100 },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: statusColors[params.row.type] || "#757575",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {params.row.type || "Unknown"}
        </span>
      ),
    },
    {
      field: "publishedMints",
      headerName: "Published Mints",
      type: "number",
      width: 100,
    },
    {
      field: "awaitingMints",
      headerName: "Awaiting Mints",
      type: "number",
      width: 100,
    },
    { field: "boost", headerName: "Boost", type: "number", width: 100 },
    {
      field: "rewards",
      headerName: "Rewards",
      type: "number",
      width: 100,
    },
  ];

  // CSV Export handling
  const handleExportClick = () => {
    if (selectedRows.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select at least one user to export.",
        severity: "error",
      });
      return;
    }
    setIsExportModalOpen(true);
  };

  const handleExport = (amount, comment) => {
    // Get selected users data
    const selectedUsers = data.filter((user) => selectedRows.includes(user.id));

    // Create CSV content without headers
    const csvRows = selectedUsers.map((user) => [
      user.walletAddress,
      amount,
      "EQCcXKwYGCCZnzQj9vwwg8Y-F3d8H7cow-Mwgj8pTFruBfP8",
      comment,
    ]);

    const csvContent = csvRows
      .map((row) =>
        row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(",")
      )
      .join("\n");

    // Download CSV
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Используем временный элемент для скачивания
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = url;
    tempLink.setAttribute("download", "users_export.csv");

    // Добавляем в DOM, запускаем скачивание и сразу удаляем
    document.body.appendChild(tempLink);
    tempLink.click();

    // Очистка после небольшой задержки
    setTimeout(() => {
      document.body.removeChild(tempLink);
      URL.revokeObjectURL(url);
    }, 100);

    setSnackbar({
      open: true,
      message: "CSV exported successfully!",
      severity: "success",
    });
  };

  // Row click handling
  const handleRowClick = (params, event) => {
    if (
      event.target.closest(".MuiDataGrid-checkboxInput") ||
      event.target.closest(".MuiCheckbox-root") ||
      event.target.closest(".MuiDataGrid-columnHeaderCheckbox")
    ) {
      return;
    }
    handleOpen(params.row);
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "background.default",
        color: "text.primary",
        borderRadius: 2,
        padding: 2,
      }}
    >
      {/* Header */}
      <Typography variant="h4" sx={{ paddingLeft: "20px", mb: 3 }}>
        Manage Users
      </Typography>
      <Divider sx={{ my: 3 }} />

      {/* Filters */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          padding: "20px",
          marginBottom: 2,
        }}
      >
        <Button
          variant={activeFilter === "all" ? "contained" : "outlined"}
          onClick={() => handleFilter("all")}
        >
          All
        </Button>
        <Button
          variant={activeFilter === "creator" ? "contained" : "outlined"}
          onClick={() => handleFilter("creator")}
        >
          Creators
        </Button>
        <Button
          variant={activeFilter === "user" ? "contained" : "outlined"}
          onClick={() => handleFilter("user")}
        >
          Users
        </Button>
      </Stack>

      {/* Search */}
      <TextField
        label="Search by ID or Wallet Address"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
        value={searchQuery}
      />

      {/* Export Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleExportClick}
        sx={{ mb: 2 }}
      >
        Export to CSV
      </Button>

      {/* Data Grid */}
      {isLoading ? (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 400 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            checkboxSelection
            onRowClick={handleRowClick}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectedRows(newSelectionModel);
              console.log("Selected rows:", newSelectionModel);
            }}
            rowSelectionModel={selectedRows}
            disableRowSelectionOnClick={false}
          />
        </Box>
      )}

      {/* Modal */}
      <UserDetailsModal
        open={isModalOpen}
        onClose={handleClose}
        userId={selectedUser}
      />

      {/* Export Modal */}
      <ExportModal
        open={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={handleExport}
        selectedCount={selectedRows.length}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
