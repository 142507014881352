import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Modal,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";
import ModerationNFTModal from "./ModerationNFTModal";

const getStatusStyles = (theme, status) => ({
  backgroundColor:
    status === "creator"
      ? theme.palette.success.main
      : theme.palette.warning.main,
  color:
    status === "creator"
      ? theme.palette.getContrastText(theme.palette.success.main)
      : theme.palette.getContrastText(theme.palette.warning.main),
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.875rem",
  display: "inline-block",
});

const imageModalStyle = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  maxHeight: "90%",
  bgcolor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
});

export default function ModerationNFTs() {
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);

  const placeholderAvatar = "https://via.placeholder.com/150?text=No+Avatar";

  const calculateDaysAgo = (date) => {
    const currentDate = new Date();
    const createdDate = new Date(date);
    const diffTime = Math.abs(currentDate - createdDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const resp = await axios.get(
        `${BASE_URL}/api/collection_items/moderation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const processedData = resp.data.data.map((item) => ({
        ...item,
        id: item._id,
        daysAgo: calculateDaysAgo(item.createdAt),
        userType: item.user?.type || "N/A",
      }));

      setData(processedData);
      setFilteredData(processedData);
    } catch (error) {
      console.error("Error fetching NFTs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilter = (filter) => {
    setActiveFilter(filter);
    const filtered =
      filter === "all" ? data : data.filter((item) => item.userType === filter);

    const searchedData = filtered.filter(
      (item) =>
        item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.name &&
          item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.user_address &&
          item.user_address.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    setFilteredData(searchedData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleFilter(activeFilter);
  };

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleImageOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageLoading(true);
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
    setSelectedImage("");
  };

  const columns = [
    { field: "_id", headerName: "ID", width: 150 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "categoryTitle", headerName: "Category", width: 150 },
    { field: "level", headerName: "Level", type: "number", width: 100 },
    {
      field: "daysAgo",
      headerName: "Days Ago",
      type: "number",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography>{params.row.daysAgo} days</Typography>
        </Box>
      ),
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 120,
      renderCell: (params) => (
        <Typography sx={getStatusStyles(theme, params.row.userType)}>
          {params.row.userType || "N/A"}
        </Typography>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) =>
        params.row?.image?.previewUrl ? (
          <Box
            component="img"
            src={params.row.image.previewUrl}
            alt={params.row.name}
            sx={{
              width: "100%",
              height: "auto",
              cursor: "pointer",
              borderRadius: 1,
              boxShadow: theme.shadows[1],
            }}
            onClick={() => handleOpen(params.row)}
          />
        ) : (
          "No Image"
        ),
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button
          variant={activeFilter === "all" ? "contained" : "outlined"}
          onClick={() => handleFilter("all")}
        >
          All
        </Button>
        <Button
          variant={activeFilter === "user" ? "contained" : "outlined"}
          onClick={() => handleFilter("user")}
        >
          User
        </Button>
        <Button
          variant={activeFilter === "creator" ? "contained" : "outlined"}
          onClick={() => handleFilter("creator")}
        >
          Creator
        </Button>
      </Stack>
      <TextField
        label="Search by ID, Name or Wallet Address"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        onChange={handleSearch}
        value={searchQuery}
      />
      {loading ? (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 400 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Box sx={{ height: 1160, padding: 0, width: "100%", margin: 0 }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 20 },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
      <Modal open={open} onClose={handleClose}>
        <ModerationNFTModal
          open={open}
          onClose={handleClose}
          selectedRow={selectedRow}
          placeholderAvatar={placeholderAvatar}
          handleImageOpen={handleImageOpen}
        />
      </Modal>
      <Modal open={imageModalOpen} onClose={handleImageClose}>
        <Box sx={imageModalStyle(theme)}>
          {isImageLoading && (
            <CircularProgress
              size={64}
              sx={{ position: "absolute", color: theme.palette.primary.light }}
            />
          )}
          <Box
            component="img"
            src={selectedImage}
            alt="Selected"
            onLoad={() => setIsImageLoading(false)}
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              display: isImageLoading ? "none" : "block",
              borderRadius: 1,
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
}
