import {
  Card,
  CardContent,
  Typography,
  Stack,
  Divider,
  Grid,
} from "@mui/material";
import InfoField from "./InfoField";
import StatCard from "./StatCard";

const PlatformStats = ({ user, statistics }) => (
  <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Platform Statistics
      </Typography>
      <Stack spacing={2}>
        <InfoField
          label="Account Created"
          value={new Date(user.createdAt).toLocaleDateString()}
        />
        <InfoField label="Tribe Balance" value={user.balanceId?.balance || 0} />
        <InfoField label="Points" value={user.point} />

        <Divider />

        <Typography variant="subtitle2">Minting Statistics</Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <StatCard
              title="Pending"
              value={statistics.pendingMints}
              color="warning.main"
            />
          </Grid>
          <Grid item xs={4}>
            <StatCard
              title="Awaiting"
              value={statistics.awaitingMints}
              color="info.main"
            />
          </Grid>
          <Grid item xs={4}>
            <StatCard
              title="Published"
              value={statistics.publishedMints}
              color="success.main"
            />
          </Grid>
        </Grid>
      </Stack>
    </CardContent>
  </Card>
);

export default PlatformStats;
