import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  CircularProgress,
  Snackbar,
  Alert,
  Backdrop,
  Paper,
} from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";
import GlobalCategoryForm from "./GlobalCategoryForm";
import SimpleCategoryForm from "./SimpleCategoryForm";
import { GlobalCategoryTreeItem } from "./CategoryTreeItem";

export default function CategoriesManagement() {
  const [globalCategories, setGlobalCategories] = useState([]);
  const [simpleCategories, setSimpleCategories] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGlobalCategory, setSelectedGlobalCategory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const [globalRes, simpleRes] = await Promise.all([
        axios.get(`${BASE_URL}/global-categories`),
        axios.get(`${BASE_URL}/api/simple-categories`),
      ]);

      setGlobalCategories(globalRes.data.data);

      // Группируем простые категории по globalCategory.id
      const grouped = simpleRes.data.data.reduce((acc, category) => {
        const globalId = category.globalCategory.id; // Используем id вместо _id
        if (!acc[globalId]) {
          acc[globalId] = [];
        }
        acc[globalId].push(category);
        return acc;
      }, {});

      console.log("Grouped simple categories:", grouped); // Для отладки
      setSimpleCategories(grouped);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch categories",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddGlobalCategory = () => {
    setSelectedCategory(null);
    setModalType("global");
    setModalOpen(true);
  };

  const handleEditGlobalCategory = (category) => {
    setSelectedCategory(category);
    setModalType("global");
    setModalOpen(true);
  };

  const handleDeleteGlobalCategory = async (id) => {
    if (!window.confirm("Are you sure you want to delete this category?"))
      return;

    try {
      await axios.delete(`${BASE_URL}/api/global-categories/${id}`);
      await fetchCategories();
      setSnackbar({
        open: true,
        message: "Category deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to delete category",
        severity: "error",
      });
    }
  };

  const handleSubmitGlobalCategory = async (formData) => {
    try {
      if (selectedCategory) {
        await axios.put(
          `${BASE_URL}/global-categories/${selectedCategory._id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        await axios.post(`${BASE_URL}/global-categories`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      await fetchCategories();
      setModalOpen(false);
      setSnackbar({
        open: true,
        message: `Category ${
          selectedCategory ? "updated" : "created"
        } successfully`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to save category",
        severity: "error",
      });
    }
  };

  const handleAddSimpleCategory = (globalCategory) => {
    setSelectedCategory(null);
    setSelectedGlobalCategory(globalCategory);
    setModalType("simple");
    setModalOpen(true);
  };

  const handleEditSimpleCategory = (category) => {
    setSelectedCategory(category);
    setModalType("simple");
    setModalOpen(true);
  };

  const handleDeleteSimpleCategory = async (id) => {
    if (!window.confirm("Are you sure you want to delete this category?"))
      return;

    try {
      await axios.delete(`${BASE_URL}/api/simple-categories/${id}`);
      await fetchCategories();
      setSnackbar({
        open: true,
        message: "Simple category deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to delete category",
        severity: "error",
      });
    }
  };

  const handleSubmitSimpleCategory = async (formData) => {
    try {
      if (selectedCategory) {
        await axios.put(
          `${BASE_URL}/api/simple-categories/${selectedCategory._id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        await axios.post(`${BASE_URL}/api/simple-categories`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      await fetchCategories();
      setModalOpen(false);
      setSnackbar({
        open: true,
        message: `Simple category ${
          selectedCategory ? "updated" : "created"
        } successfully`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Failed to save category",
        severity: "error",
      });
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCategory(null);
    setSelectedGlobalCategory(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Categories Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddGlobalCategory}
        >
          Add Global Category
        </Button>
      </Stack>

      <Paper elevation={2} sx={{ p: 2 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              flexGrow: 1,
              maxWidth: "100%",
              overflowY: "auto",
            }}
          >
            {globalCategories.map((category) => (
              <GlobalCategoryTreeItem
                key={category._id}
                category={category}
                onEdit={handleEditGlobalCategory}
                onDelete={handleDeleteGlobalCategory}
                onAddSimple={handleAddSimpleCategory}
                onEditSimple={handleEditSimpleCategory}
                onDeleteSimple={handleDeleteSimpleCategory}
                simpleCategories={simpleCategories}
              />
            ))}
          </TreeView>
        )}
      </Paper>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {modalType === "global"
            ? `${selectedCategory ? "Edit" : "Create"} Global Category`
            : `${selectedCategory ? "Edit" : "Create"} Simple Category`}
        </DialogTitle>
        <DialogContent>
          {modalType === "global" ? (
            <GlobalCategoryForm
              initialData={selectedCategory}
              onSubmit={handleSubmitGlobalCategory}
              onCancel={handleCloseModal}
            />
          ) : (
            <SimpleCategoryForm
              initialData={selectedCategory}
              onSubmit={handleSubmitSimpleCategory}
              onCancel={handleCloseModal}
              globalCategories={globalCategories}
              selectedGlobalCategory={selectedGlobalCategory}
            />
          )}
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
