import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  LinearProgress,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Card,
  Stack,
  Typography,
} from "@mui/material";

import { BASE_URL } from "../../services/baseURL";
import CollectionsList from "../../Components/User/CollectionsList";
import ItemsList from "../../Components/User/ItemsList";
import ProfileHeader from "../../Components/User/ProfileHeader";
import UserInfo from "../../Components/User/UserInfo";
import StatisticsOverview from "../../Components/User/StatisticsOverview";
import TabPanel from "../../Components/User/Sidebar/TabPanel";
import ContactInfo from "../../Components/User/Sidebar/ContactInfo";
import PlatformStats from "../../Components/User/Sidebar/PlatformStats";
import LevelDialog from "../../Components/User/LevelDialog";

export default function UserProfile() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [openLevelDialog, setOpenLevelDialog] = useState(false);
  const [newLevel, setNewLevel] = useState("");
  const [updating, setUpdating] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        if (data.success) {
          setUserData(data.data);
          setNewLevel(data.data.user.level.toString());
        } else {
          throw new Error(data.message || "Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setSnackbar({
          open: true,
          message: "Failed to load user data",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleLevelUpdate = async () => {
    if (!newLevel || isNaN(newLevel) || newLevel < 1) {
      setSnackbar({
        open: true,
        message: "Please enter a valid level",
        severity: "error",
      });
      return;
    }

    setUpdating(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/admin/users/${userId}/level`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ level: parseInt(newLevel) }),
        }
      );

      if (response.ok) {
        setUserData((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            level: parseInt(newLevel),
          },
        }));
        setOpenLevelDialog(false);
        setSnackbar({
          open: true,
          message: "User level updated successfully",
          severity: "success",
        });
      } else {
        throw new Error("Failed to update level");
      }
    } catch (error) {
      console.error("Error updating level:", error);
      setSnackbar({
        open: true,
        message: "Failed to update user level",
        severity: "error",
      });
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <LinearProgress />
      </Container>
    );
  }

  if (!userData) {
    return (
      <Container>
        <Alert severity="error">User not found</Alert>
      </Container>
    );
  }

  const { user, statistics } = userData;

  const socialLinks = [
    {
      icon: "facebook",
      url: user.profile?.facebook,
      label: "Facebook",
    },
    {
      icon: "instagram",
      url: user.profile?.instagram,
      label: "Instagram",
    },
    {
      icon: "twitter",
      url: user.profile?.twitter,
      label: "Twitter",
    },
    {
      icon: "linkedin",
      url: user.profile?.linkedin,
      label: "LinkedIn",
    },
    {
      icon: "website",
      url: user.profile?.website,
      label: "Website",
    },
  ].filter((link) => link.url);

  const tabs = [
    {
      label: "Collections",
      component: CollectionsList,
      data: userData?.user?.collections || [],
    },
    {
      label: "Items",
      component: ItemsList,
      data: userData?.user?.collectionItems || [],
    },
    {
      label: "Activity",
      component: () => (
        <Typography color="text.secondary" align="center" sx={{ py: 4 }}>
          Transaction history and activity will be displayed here
        </Typography>
      ),
    },
  ];
  //   console.log(userData);
  return (
    <Container maxWidth="lg">
      <ProfileHeader user={user} onBackClick={() => navigate(-1)} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <UserInfo
            user={user}
            onLevelChange={() => setOpenLevelDialog(true)}
          />
          <StatisticsOverview statistics={statistics} />

          <Card>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>

            {tabs.map((tab, index) => (
              <TabPanel key={index} value={tabValue} index={index}>
                {typeof tab.component === "function" ? (
                  <tab.component />
                ) : (
                  <tab.component data={tab.data} />
                )}
              </TabPanel>
            ))}
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <ContactInfo profile={user.profile} socialLinks={socialLinks} />
            <PlatformStats
              user={user}
              statistics={{
                pendingMints: user.pendingMints,
                awaitingMints: user.awaitingMints,
                publishedMints: user.publishedMints,
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <LevelDialog
        open={openLevelDialog}
        onClose={() => setOpenLevelDialog(false)}
        value={newLevel}
        onChange={(e) => setNewLevel(e.target.value)}
        onUpdate={handleLevelUpdate}
        updating={updating}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
