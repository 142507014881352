import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  Grid,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/baseURL";
import NFTVerificationAlert from "./Moderation/NFTVerificationAlert";

const rejectReasons = [
  {
    id: "wrong_collection",
    label: "Wrong Collection",
    description: "NFT does not match the collection category or theme",
  },
  {
    id: "wrong_type",
    label: "Wrong Type",
    description: "NFT type does not match platform requirements",
  },
  {
    id: "prohibited_content",
    label: "Prohibited Content",
    description: "NFT contains prohibited or inappropriate content",
  },
];

const getStatusStyles = (theme, status) => ({
  backgroundColor:
    status === "awaiting publication"
      ? theme.palette.warning.main
      : theme.palette.success.main,
  color:
    status === "awaiting publication"
      ? theme.palette.getContrastText(theme.palette.warning.main)
      : theme.palette.getContrastText(theme.palette.success.main),
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "0.875rem",
  display: "inline-block",
});

export default function ModerationNFTModal({
  open,
  onClose,
  selectedRow,
  placeholderAvatar,
  handleImageOpen,
  onUpdate,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [level, setLevel] = useState("");
  const [selectedRejectReason, setSelectedRejectReason] = useState("");
  const [collectionData, setCollectionData] = useState(null);
  const [isLoadingCollection, setIsLoadingCollection] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedRow?.collection_id) {
      fetchCollectionData(selectedRow.collection_id);
    }
  }, [selectedRow]);

  const fetchCollectionData = async (collectionId) => {
    setIsLoadingCollection(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${BASE_URL}/api/collection/${collectionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch collection data");
      }
      const data = await response.json();
      console.log(data);

      setCollectionData(data);
    } catch (error) {
      console.error("Error fetching collection data:", error);
      setError("Failed to load collection information");
    } finally {
      setIsLoadingCollection(false);
    }
  };

  useEffect(() => {
    if (selectedRow) {
      setLevel(selectedRow.level || "");
    }
  }, [selectedRow]);

  if (!open || !selectedRow) return null;

  const handleUserClick = () => {
    if (selectedRow?.user?._id) {
      navigate(`/users/${selectedRow.user._id}`);
      onClose();
    }
  };

  const handleCheckProfile = () => {
    if (selectedRow?.user?._id) {
      navigate(`/users/${selectedRow.user._id}`);
    }
  };

  const handleApproveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      await fetch(
        `${BASE_URL}/api/collection_item/${selectedRow._id}/approve`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            level,
            status: "approved",
          }),
        }
      );
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error approving NFT:", error);
    }
  };

  const handleRejectClick = async () => {
    try {
      const selectedReason = rejectReasons.find(
        (r) => r.id === selectedRejectReason
      );
      const rejectMessage = `${selectedReason.label}: ${selectedReason.description}`;

      const token = localStorage.getItem("token");
      await fetch(
        `${BASE_URL}/api/moderation/reject/collection-item/${selectedRow._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            message: rejectMessage,
          }),
        }
      );
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error rejecting NFT:", error);
    }
  };

  const isCreator = selectedRow.user?.type === "creator";

  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90vw",
        maxWidth: "800px",
        maxHeight: "90vh",
        bgcolor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        p: 4,
        borderRadius: "8px",
        overflowY: "auto",
      }}
    >
      {/* User Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleUserClick}
        >
          <img
            src={
              selectedRow.user?.profile?.photo?.previewUrl || placeholderAvatar
            }
            alt="User Avatar"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "12px",
            }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              {selectedRow.user?.username || "No Username"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              User Type: <strong>{selectedRow.user?.type || "N/A"}</strong>
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={handleCheckProfile}
            sx={{ color: theme.palette.text.primary }}
          >
            Check Profile
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ color: theme.palette.text.primary }}
          >
            Close
          </Button>
        </Stack>
      </Box>

      {!isCreator && (
        <NFTVerificationAlert
          nftType={selectedRow.image?.fileType}
          globalCategory={collectionData?.globalCategory?.title}
        />
      )}

      <Divider sx={{ mb: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* Collection Information */}
          <Typography
            variant="h6"
            sx={{ mb: 2, color: theme.palette.text.primary }}
          >
            Collection Information
          </Typography>
          {isLoadingCollection ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ mb: 1, color: theme.palette.text.primary }}
              >
                <strong>Global Category:</strong>{" "}
                {collectionData?.globalCategory?.title || "N/A"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 1, color: theme.palette.text.primary }}
              >
                <strong>Category:</strong>{" "}
                {collectionData?.category?.title || "N/A"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 1, color: theme.palette.text.primary }}
              >
                <strong>Collection Name:</strong>{" "}
                {collectionData?.name || "N/A"}
              </Typography>
            </Box>
          )}

          {/* NFT Information */}
          <Typography
            variant="h6"
            sx={{ mb: 2, color: theme.palette.text.primary }}
          >
            NFT Information
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Name:</strong> {selectedRow.name || "No Name"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Description:</strong>{" "}
            {selectedRow.description || "No description"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: theme.palette.text.primary }}
          >
            <strong>Status:</strong>{" "}
            <span style={getStatusStyles(theme, selectedRow.status)}>
              {selectedRow.status || "Unknown"}
            </span>
          </Typography>

          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              Level:
            </Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              InputProps={{
                inputProps: { min: 0 },
                sx: { color: theme.palette.text.primary },
              }}
              sx={{
                width: "100px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.divider,
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.text.secondary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </Box>

          {/* Reject Reason */}
          <FormControl
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                color: theme.palette.text.primary,
              },
              "& .MuiInputLabel-root": {
                color: theme.palette.text.primary,
              },
              "& .MuiSelect-icon": {
                color: theme.palette.text.primary,
              },
            }}
          >
            <InputLabel>Rejection Reason</InputLabel>
            <Select
              value={selectedRejectReason}
              onChange={(e) => setSelectedRejectReason(e.target.value)}
              label="Rejection Reason"
            >
              {rejectReasons.map((reason) => (
                <MenuItem
                  key={reason.id}
                  value={reason.id}
                  sx={{ color: theme.palette.text.primary }}
                >
                  {reason.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedRejectReason && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {
                rejectReasons.find((r) => r.id === selectedRejectReason)
                  ?.description
              }
            </Alert>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Preview */}
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="caption"
              display="block"
              sx={{ mb: 1, color: theme.palette.text.secondary }}
            >
              Preview
            </Typography>
            <img
              src={selectedRow.image?.previewUrl || placeholderAvatar}
              alt="NFT Preview"
              style={{
                width: "100%",
                maxHeight: "300px",
                objectFit: "contain",
                borderRadius: "8px",
                marginBottom: "8px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleImageOpen(selectedRow.image?.fileUrl || placeholderAvatar)
              }
            />
          </Box>

          {/* Media Player */}
          {selectedRow.image?.fileType === "audio" && (
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography
                variant="caption"
                display="block"
                sx={{ mb: 1, color: theme.palette.text.secondary }}
              >
                Audio Player
              </Typography>
              <audio controls style={{ width: "100%" }}>
                <source
                  src={`https://media-backend.tribeton.io${
                    selectedRow.image?.localPath || ""
                  }`}
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
            </Box>
          )}
          {selectedRow.image?.fileType === "video" && (
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography
                variant="caption"
                display="block"
                sx={{ mb: 1, color: theme.palette.text.secondary }}
              >
                Video Player
              </Typography>
              <iframe
                src={selectedRow.image?.fileUrl}
                title="NFT Video Player"
                style={{ width: "100%", height: "300px", border: "none" }}
                allowFullScreen
              ></iframe>
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Action Buttons */}
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="contained"
          color="success"
          onClick={handleApproveClick}
          disabled={!level || isLoadingCollection}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleRejectClick}
          disabled={!selectedRejectReason || isLoadingCollection}
        >
          Reject
        </Button>
      </Stack>
    </Box>
  );
}
