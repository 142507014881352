import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  Box,
  Menu,
  MenuItem,
  Collapse,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CollectionsIcon from "@mui/icons-material/Collections";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarsIcon from "@mui/icons-material/Stars";
import PaidIcon from "@mui/icons-material/Paid";
import GradeIcon from "@mui/icons-material/Grade";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ThemeContext } from "../../ThemeContext";

const Layout = () => {
  const { darkMode, toggleTheme } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [serviceOpen, setServiceOpen] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleServiceMenu = () => {
    setServiceOpen(!serviceOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
    handleMenuClose();
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardOutlinedIcon />, link: "/home" },
    {
      text: "Moderation",
      icon: <AdminPanelSettingsOutlinedIcon />,
      link: "/moderation-new",
    },
    {
      text: "Manage",
      icon: <DashboardCustomizeOutlinedIcon />,
      link: "/manage-nft/all",
    },
    { text: "Users", icon: <PeopleOutlineIcon />, link: "/users" },
    { text: "Blog", icon: <ChromeReaderModeIcon />, link: "/blog" },
    { text: "Category", icon: <MenuRoundedIcon />, link: "/category" },
    { text: "Categories", icon: <MenuRoundedIcon />, link: "/categories" },
    { text: "Gallery", icon: <CollectionsIcon />, link: "/galleries" },
    // { text: "Gallery", icon: <CollectionsIcon />, link: "/gallery" },
  ];

  const serviceItems = [
    {
      text: "Price for Like",
      icon: <ThumbUpIcon />,
      link: "/changePriceLike",
    },
    { text: "Rewards", icon: <EmojiEventsIcon />, link: "/rewards" },
    { text: "Boost User", icon: <StarsIcon />, link: "/boost-user" },
    { text: "Withdrawal", icon: <PaidIcon />, link: "/withdrawal" },
    { text: "Point", icon: <GradeIcon />, link: "/point" },
  ];

  useEffect(() => {
    const allItems = [...menuItems, ...serviceItems];
    const currentItem = allItems.find((item) =>
      location.pathname.includes(item.link)
    );
    setPageTitle(currentItem ? currentItem.text : "Dashboard");
  }, [location.pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          backgroundColor: "background.default",
          width: isExpanded ? 250 : 80,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isExpanded ? 250 : 80,
            boxSizing: "border-box",
            transition: "width 0.3s ease",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <IconButton onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Divider />

        <List>
          {menuItems.map((item, index) => (
            <Link
              to={item.link}
              style={{ textDecoration: "none", color: "inherit" }}
              key={index}
            >
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {isExpanded && <ListItemText primary={item.text} />}
              </ListItem>
            </Link>
          ))}

          <ListItem
            button
            onClick={toggleServiceMenu}
            sx={{ justifyContent: isExpanded ? "flex-start" : "center" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <ListItemIcon sx={{ minWidth: 0, justifyContent: "center" }}>
                <MiscellaneousServicesIcon />
              </ListItemIcon>
              {isExpanded && <ListItemText primary="Service" sx={{ ml: 1 }} />}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: isExpanded ? "auto" : 0,
                }}
              >
                {serviceOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </Box>
          </ListItem>

          <Collapse in={serviceOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {serviceItems.map((service, index) => (
                <Link
                  to={service.link}
                  style={{ textDecoration: "none", color: "inherit" }}
                  key={index}
                >
                  <ListItem button sx={{ pl: isExpanded ? 4 : 2 }}>
                    <ListItemIcon>{service.icon}</ListItemIcon>
                    {isExpanded && <ListItemText primary={service.text} />}
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        </List>
      </Drawer>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.default",
        }}
      >
        <AppBar
          position="static"
          sx={{
            backgroundColor: darkMode ? "#1E1E1E" : "#1976d2",
            color: darkMode ? "#E0E0E0" : "#FFFFFF",
            transition: "background-color 0.3s ease",
          }}
        >
          <Toolbar>
            <img
              src="/images/logo.png"
              alt="logo"
              style={{ maxWidth: "30px", height: "auto" }}
            />
            <Typography variant="h6" sx={{ flexGrow: 1, ml: 2 }}>
              TRIBE Admin Panel
            </Typography>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {pageTitle} Page
            </Typography>
            <IconButton color="inherit" onClick={toggleTheme}>
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <IconButton color="inherit" onClick={handleMenuOpen} sx={{ ml: 2 }}>
              <AccountCircleIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            flex: 1,
            p: 3,
            backgroundColor: "background.default",
            color: "text.primary",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
