import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { Favorite, LocalOffer } from "@mui/icons-material";

const ItemsList = ({ data }) => {
  console.log("ItemsList received props:", { data });
  console.log(
    "ItemsList data type:",
    Array.isArray(data) ? "array" : typeof data
  );

  // Защита от undefined
  if (!data) {
    console.log("ItemsList: data is undefined");
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography color="text.secondary">No data available</Typography>
      </Box>
    );
  }

  // Проверка на пустой массив
  if (!Array.isArray(data) || data.length === 0) {
    console.log("ItemsList: data is empty array or not an array");
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography color="text.secondary">No items found</Typography>
      </Box>
    );
  }

  const getPriceDisplay = (item) => {
    if (item.tonPrice) {
      return `${item.tonPrice} TON`;
    }
    if (item.price) {
      return `${item.price} TRIBE`;
    }
    return null;
  };

  console.log("ItemsList: rendering", data.length, "items");

  return (
    <Grid container spacing={2}>
      {data.map((item) => {
        console.log("Rendering item:", item._id);
        const priceDisplay = getPriceDisplay(item);

        return (
          <Grid item xs={12} sm={6} md={4} key={item._id}>
            <Card>
              <Box sx={{ position: "relative", paddingTop: "100%" }}>
                <img
                  src={item.image?.previewUrl || "/default-item.png"}
                  alt={item.name}
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    console.log("Image load error for item:", item._id);
                    e.target.src = "/default-item.png";
                  }}
                />
                {item.saleStatus === "sale" && priceDisplay && (
                  <Chip
                    label={priceDisplay}
                    color="primary"
                    icon={<LocalOffer fontSize="small" />}
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      bgcolor: "primary.main",
                      color: "white",
                      "& .MuiChip-icon": {
                        color: "white",
                      },
                    }}
                  />
                )}
              </Box>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {item.name || "Unnamed Item"}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mb: 2,
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {item.description || "No description"}
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  <Chip
                    size="small"
                    label={item.categoryTitle}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    size="small"
                    icon={<Favorite fontSize="small" />}
                    label={item.likes}
                    variant="outlined"
                  />
                  <Chip
                    size="small"
                    label={`Level ${item.level}`}
                    color="secondary"
                    variant="outlined"
                  />
                  {item.status && (
                    <Chip
                      size="small"
                      label={item.status}
                      color={
                        item.status === "published" ? "success" : "warning"
                      }
                      variant="outlined"
                    />
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default React.memo(ItemsList);
