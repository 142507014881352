// src/components/Galleries/api/galleries.js

import { BASE_URL } from "../../../services/baseURL";

const API_BASE_URL = `${BASE_URL}/api/nft-showcases`;

export const fetchGalleries = async () => {
  try {
    const response = await fetch(API_BASE_URL, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      // Отключаем передачу лишних заголовков
      credentials: "omit",
      // Устанавливаем максимальное время ожидания
      signal: AbortSignal.timeout(10000),
    });

    if (!response.ok) {
      throw new Error(
        response.status === 431
          ? "Ошибка загрузки данных. Попробуйте обновить страницу"
          : "Failed to fetch galleries"
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching galleries:", error);
    throw error;
  }
};

// Получение одной галереи по ID
export const fetchGalleryById = async (id) => {
  const response = await fetch(`${API_BASE_URL}/${id}`);
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to fetch gallery");
  }
  return response.json();
};

// Создание галереи
export const createGallery = async (formData) => {
  const response = await fetch(API_BASE_URL, {
    method: "POST",
    body: formData, // FormData содержит title, description, coordinates, isEnabled и image
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to create gallery");
  }
  return response.json();
};

// Обновление галереи
export const updateGallery = async (id, formData) => {
  const response = await fetch(`${API_BASE_URL}/${id}`, {
    method: "PATCH",
    body: formData,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to update gallery");
  }
  return response.json();
};

// Удаление галереи
export const deleteGallery = async (id) => {
  const response = await fetch(`${API_BASE_URL}/${id}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to delete gallery");
  }
  return response.json();
};

// Добавление NFT в галерею
export const addNftToGallery = async (galleryId, nftData) => {
  const response = await fetch(`${API_BASE_URL}/${galleryId}/nfts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(nftData),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to add NFT to gallery");
  }
  return response.json();
};

// Удаление NFT из галереи
export const removeNftFromGallery = async (galleryId, nftId) => {
  const response = await fetch(`${API_BASE_URL}/${galleryId}/nfts/${nftId}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to remove NFT from gallery");
  }
  return response.json();
};
