import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
  CardActions,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteGallery, fetchGalleries } from "./api/galleries";

function GalleriesPage() {
  const navigate = useNavigate();
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    loadGalleries();
  }, []);

  const loadGalleries = async () => {
    try {
      const data = await fetchGalleries();
      setGalleries(data);
      setError("");
    } catch (err) {
      setError("Ошибка при загрузке галерей");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Вы уверены, что хотите удалить эту галерею?")) {
      return;
    }

    try {
      await deleteGallery(id);
      setGalleries(galleries.filter((gallery) => gallery._id !== id));
    } catch (err) {
      setError("Ошибка при удалении галереи");
      console.error("Error:", err);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4" component="h1">
          NFT Галереи
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/galleries/create")}
        >
          Создать галерею
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {galleries.map((gallery) => (
          <Grid item xs={12} sm={6} md={4} key={gallery._id}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardMedia
                component="img"
                height="200"
                image={gallery.image.previewUrl}
                alt={gallery.title}
                sx={{ objectFit: "cover" }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {gallery.title}
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 2 }}>
                  {gallery.description}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <LocationOnIcon color="action" fontSize="small" />
                  <Typography variant="body2" color="text.secondary">
                    {`${gallery.coordinates.latitude}, ${gallery.coordinates.longitude}`}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions
                sx={{ justifyContent: "space-between", px: 2, pb: 2 }}
              >
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/galleries/edit/${gallery._id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDelete(gallery._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/galleries/${gallery._id}`)}
                >
                  Просмотр
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {galleries.length === 0 && !error && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h6" color="text.secondary">
            Галереи пока не созданы
          </Typography>
          <Typography color="text.secondary" sx={{ mt: 1 }}>
            Создайте свою первую галерею, нажав кнопку "Создать галерею"
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default GalleriesPage;
