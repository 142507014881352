import Grid from "@mui/material/Grid";
import StatCard from "./Sidebar/StatCard";

export const StatisticsOverview = ({ statistics }) => (
  <Grid container spacing={2} sx={{ mb: 4 }}>
    {Object.entries(statistics).map(([key, value]) => (
      <Grid item xs={6} sm={3} key={key}>
        <StatCard title={key.replace(/([A-Z])/g, " $1").trim()} value={value} />
      </Grid>
    ))}
  </Grid>
);

export default StatisticsOverview;
